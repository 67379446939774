/**
 * Mapping for translation keys, so we don't have to use long text in source code. Please keep in mind, that the
 * german default translations are the keys, so don't change them, if you wan't to change the text.
 * Instead change the de_DE translations!
 */
export const KEYS = {
  EDITOR: {
    LEFT_TEXT: 'Text links',
    RIGHT_TEXT: 'Text rechts',
    CONFIG_ERROR: 'Konfigurationsfehler',
    QUESTIONNAIRE_API_URL_UNSET: 'API URL zum Abrufen von Fragebogen-Vorlagen wurde nicht gefunden. Bitte konfigurieren:',
    ASK_RESUME_QUESTIONNAIRE: 'Es ist eine nicht abgeschlossene Sitzung vorhanden. Möchten sie den Fragebogen jetzt weiter bearbeiten?',
    QUESTIONNAIRE_EDITOR: 'Editor',
    ENTER_TITLE: 'Titel eingeben...',
    QUESTIONNAIRE_TYPE: 'Typ',
    QUESTIONNAIRE: 'Fragebogen',
    INFORMATION_SHEET: 'Aufklärungsbogen',
    STATUS: 'Status',
    DRAFT: 'Entwurf',
    APPROVED: 'Freigegeben',
    REV: 'Rev.',
    FILTER_GROUPS: 'Fachgruppen',
    SAVE: 'Speichern',
    SHOW: 'Zeige',
    NO_CONTENT: 'Keine Inhalte',
    ALL_CONTENT: 'Alle Inhalte',
    ALL_IMAGES: 'Alle Bilder',
    ALL_VIDEOS: 'Alle Videos',
    ALL_AUDIO: 'Alle Audio',
    ALL_DESCRIPTIONS: 'Alle Beschreibungen',
    ALL_DEPENDENCIES: 'Alle Abhängigkeiten',
    EXPAND_ELEMENTS: 'Elemente ausklappen',
    FOLD_ELEMENTS: 'Elemente einklappen',
    JUMP_TO_ELEMENT: 'Zu Element springen',
    ELEMENTS: 'Elemente',
    FULL_SCREEN: 'Vollbild',
    OPTIONS: 'Optionen',
    DESCRIPTION: 'Beschreibung',
    IMAGE: 'Bild',
    ADD_IMAGE: 'Bild einfügen',
    SCORING: 'Scoring',
    PDF_FORMS: 'PDF Formulare',
    EXTERN_VARIABLES: 'Externe Variablen',
    FORMULA_VARIABLES: 'Formeln',
    EXCEL: 'Tabellen',
    FORMULA: 'Formel',
    PDF: 'PDF',
    GDT: 'GDT',
    FILL_OPTIONS: 'Ausfüllen',
    GENERATE_INTERNAL_PDF: 'Internes PDF erstellen',
    FORCE_MANDATORY: "Beantwortung von Pflichtfragen erzwingen",
    SKIP_LIST: "Fragebogen Liste überspringen bei einzelnem Fragebogen",
    SKIP_SUBMIT_CONFIRMATION: "Bestätigungsdialog beim Abgeben des Fragebogens überspringen",
    ADD_QUESTIONNAIRE_TITLE_TO_GDT: "Fragebogen Titel in GDT Datei einfügen",
    ADD_SCORING_TO_GDT: 'Scoring Ergebnisse in GDT Datei einfügen',
    SCORING_VARIABLE: 'Scoring Variable',
    ADD_SCORING_VARIABLE: 'Scoring Variable einfügen',
    ADD_EXTERN_VARIABLE: 'Externe Variable einfügen',
    ADD_FORMULA_VARIABLE: 'Formel Variable einfügen',
    SCORING_VALUE_FOR_THIS_ANSWER: 'Scoring Wert für diese Antwort',
    ENTER_DESCRIPTION: 'Beschreibung eingeben...',
    ERROR_LOADING_QUESTIONNAIRE: 'Beim Laden des Fragebogens ist ein Fehler aufgetreten',
    SUCCESSFULLY_SAVED_QUESTIONNAIRE: 'Der Fragebogen wurde erfolgreich gespeichert',
    ERROR_SAVING_QUESTIONNAIRE: 'Beim Speichern des Fragebogens ist ein Fehler aufgetreten',
    PLEASE_INSERT_TITLE_TO_SAVE: 'Achtung: Bitte Fragebogen Titel eingeben, um zu speichern',
    QUESTION_DELETE_ELEMENT: 'Sind sie sicher, dass sie dieses Element entfernen möchten?',
    QUESTIONNAIRE_LIMIT_REACHED: 'Lizenz Limit erreicht. Es können keine weiteren Fragebögen angelegt werden.',
    DYNAMIC: 'Dynamisch',
    STATIC: 'Statisch',
    COPY_QUESTION: 'Frage kopieren',
    INFO_TEXT: 'Infotext',
    PAGE_BREAK: 'Seitenumbruch',
    INPUT: 'Eingabe',
    NUMBER: 'Zahl',
    DATE: 'Datum',
    FREE_TEXT: 'Freitext',
    CHOICE: 'Auswahl',
    SINGLE_CHOICE: 'Eine Antwort',
    MULTIPLE_CHOICE: 'Mehrere Antworten',
    QUESTION_MATRIX: 'Fragen-Matrix',
    MEDIA: 'Medien',
    TAKE_PICTURE: 'Bild aufnehmen',
    DRAWING: 'Zeichnung',
    SIGNATURE: 'Unterschrift',
    TEMPLATES: 'Vorlagen',
    YES_NO: 'Ja/Nein',
    YES_NO_MISC: 'Ja/Nein/Sonstiges',
    ADD_ELEMENT: 'Element hinzufügen',
    ERROR_READING_QUESTIONNAIRE_JSON: 'Fragebogen JSON konnte nicht gelesen werden. Fehler:',
    QUESTIONNAIRE_JSON: 'Fragebogen JSON',
    IMPORT: 'Import',
    EXPORT: 'Export',
    DEBUGGING: 'Debugging',
    UNPACKED_EXPORT: 'Unpacked Export',
    QUESTIONNAIRE_PREVIEW: 'Fragebogen Vorschau',
    QUESTION: 'Frage',
    VARIABLE: 'Variable',
    AND: 'UND',
    OR: 'ODER',
    GROUP: 'Gruppe',
    CONDITION: 'Bedingung',
    SHOW_THIS_QUESTION_IF: 'Diese Frage einblenden wenn:',
    ADD_CONDITION: 'Bedingung hinzufügen',
    BACKGROUND_COLOR: 'Hintergrundfarbe',
    PEN_COLOR: 'Stiftfarbe',
    HEIGHT: 'Höhe',
    BACKGROUND_IMAGE: 'Hintergrundbild',
    ERROR_TRANSFERRING_IMAGE: 'Beim Übertragen des Bildes ist ein Fehler aufgetreten!',
    EDIT: 'Bearbeiten',
    REMOVE: 'Entfernen',
    ADD_ANSWER: 'Antwort hinzufügen',
    ADD_ENTRY: 'Eintrag hinzufügen',
    DATE_YMD_HM_TEXT: 'Monat, Jahr und Tag + Zeit : DD.MM.YYYY HH:mm',
    DATE_YMD_TEXT: 'Monat, Jahr und Tag: DD.MM.YYYY',
    DATE_YM_TEXT: 'Monat und Jahr: MM.YYYY',
    DATE_Y_TEXT: 'Jahr: YYYY',
    ROWS: 'Zeilen',
    ROW: 'Zeile',
    COLUMNS: 'Spalten',
    COLUMN: 'Spalte',
    VARIABLES: 'Variablen',
    VALIDATION: 'Validierung',
    ADD_ROW: 'Zeile hinzufügen',
    ADD_COLUMN: 'Spalte hinzufügen',
    ANSWERS_PER_ROW: 'Antworten pro Zeile',
    PREVIEW: 'Vorschau',
    MIN: 'Min',
    MAX: 'Max',
    STEPS: 'Schritte',
    SCALE: 'Skala',
    UNIT: 'Einheit',
    UNIT_EXAMPLE: 'Bsp. Kg',
    DISPLAY_TYPE: 'Anzeigetyp',
    INPUT_FIELD: 'Eingabefeld',
    SLIDER: 'Slider',
    TYPE_OF_SIGNATURE: 'Art der Unterschrift',
    QUESTION_TYPE_HAS_NO_CONFIGURABLE_CONTENT: 'Dieser Fragetyp hat keinen konfigurierbaren Inhalt',
    CLOSE: 'Schließen',
    SCOPE: 'Gültigkeitsbereich',
    VARIABLE_NAME: 'Name der Variable',
    RETURN_VALUE: 'Rückgabewert',
    SAVE_VARIABLE: 'Variable speichern',
    RESET_VARIABLE: 'Variable zurücksetzen',
    DELETE_VARIABLE: 'Variable löschen',
    NO_VARIABLES_CONFIGURED: 'Es wurden keine Variablen konfiguriert.',
    INVALID_VAR_NAME_TEXT: 'Variablenname ungültig. Mind. 3 Zeichen. Nur [a-zA-Z0-9_] erlaubt.',
    INVALID_NUMBER_TEXT: 'Eingabe ungültig. Nur Zahlen erlaubt.',
    DUPLICATE_VAR_NAME_TEXT: 'Variablenname wird bereits bei einer anderen Frage verwendet.',
    INVALID_EXPRESSION: 'Eingabe ungültig. Nur Variablen, Zahlen, Arithmetische Operatoren und Math Funktionen erlaubt.',
    INVALID_CIRCULAR_EXPRESSION: 'Eingabe ungültig. Formel Variablen können nicht in einer Formel verwendet werden.',
    ACTIVE: 'Aktiv',
    MANDATORY: 'Pflicht',
    MARK: 'Markieren',
    HIDE_IN_PDF: 'In PDF nicht anzeigen',
    HIDE_IN_APP: 'Beim Ausfüllen nicht anzeigen',
    HIDE_IN_XLS: 'In Tabellen Export nicht anzeigen',
    UPLOAD: 'Upload',
    IMAGES: 'Bilder',
    VIDEOS: 'Videos',
    AUDIO: 'Audio',
    ADD_IMAGES: 'Bilder hinzufügen',
    ADD_VIDEOS: 'Videos hinzufügen',
    ADD_AUDIO: 'Audio hinzufügen',
    DEPENDENCIES: 'Abhängigkeiten',
    MODE: 'Modus',
    MODE_DEFAULT: 'Standard',
    MODE_EXTENDED: 'Erweitert',
    SOURCE: 'Quelle',
    TARGET: 'Ziel',
    TYPE_STRING: 'String',
    TYPE_NUMERIC: 'Numeric',
    TYPE_DATE: 'Date',
    REGULAR_EXPRESSION: 'Regulärer Ausdruck',
    ERROR: 'Fehler',
    HINT: 'Hinweis',
    MAIL: 'Mail',
    SEND_INTERNAL_PDF: 'Internes PDF senden',
    SEND_FILLED_PDF: 'Ausgefülltes PDF senden',
    USER: 'Benutzer',
    FILLER: 'Ausfüller',
    NET_DIAGRAM: 'Netzdiagramm',
    OMIT_PATIENT_DATA: 'Patientendaten auslassen',
    NUMBER_RANGE: 'Zahlenbereich',
    ENTRIES: 'Einträge',
    DISTRIBUTION: 'Verteilung',
    DATASET_TITLE: 'Datensatz Titel',
  }
};

import {NgModule, Optional} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {QRCodeModule} from 'angularx-qrcode';
import {LayoutService} from '@ngmedax/layout';
import {ConfigService} from '@ngmedax/config';
import {LoginEventService} from '@ngmedax/login';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {ToastService} from '@ngmedax/toast';
import {RegistryService} from '@ngmedax/registry';
import {QuestionnaireSelectModule} from '@ngmedax/questionnaire-select';

import {NAVIGATION} from './patient.navigation';
import {PatientService} from './services/patient.service';
import {ClipboardService} from './services/clipboard.service';
import {PatientGridComponent} from './components/patient-grid.component';
import {PatientCrudComponent} from './components/patient-crud.component';
import {TRANSLATION_DEFAULT_SCOPE} from '../../constants';
import {KEYS} from '../../translation-keys';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {routing} from './patient.routing';

export interface PatientModule extends Translatable {};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule,
    ReactiveFormsModule,
    NgbModule,
    QuestionnaireSelectModule,
    routing
  ],
  declarations: [
    PatientGridComponent,
    PatientCrudComponent,
    TruncatePipe,
    DatexPipe
  ],
  exports: [
    PatientGridComponent,
    PatientCrudComponent
  ],
  providers: [
    ClipboardService,
    PatientService
  ]
})

@Translatable({scope: TRANSLATION_DEFAULT_SCOPE, keys: KEYS})
export class PatientModule {
  constructor(
    layoutService: LayoutService,
    loginEvents: LoginEventService,
    registryService: RegistryService,
    private patientService: PatientService,
    private configService: ConfigService,
    @Optional() private translationService: TranslationService,
    @Optional() private toastService: ToastService,
  ) {
    layoutService.addMenuEntry(NAVIGATION);

    // disables link to create new patient if feature is disabled by config
    this.configService.onAfterLoad().subscribe(() => {
      // !0 is true but !'0' is false
      const disablePatientNew = !(this.configService.get('feature.patient.new') == true);

      if (!disablePatientNew) {
        return;
      }

      const menu = layoutService.getMenu();
      const empty = [{children: []}];
      const patientMenu = (menu.filter(m => m.path === '/module/patient/') || empty)[0];
      const createNew = (patientMenu.children.filter(m => m.path === '/module/patient/crud/new') || empty)[0];
      createNew.disabled = true;
    });

    loginEvents.onAfterLogin().subscribe(() => {
      setTimeout(() => {
        const license = registryService.get('license');

        if (!license || !license.constraint || !license.constraint.numSurveysPerMonth) {
          return;
        }

        this.showWarningIfSurveyLimitAlmostReached(license.constraint.numSurveysPerMonth);
      }, 5000);
    });
  }

  private showWarningIfSurveyLimitAlmostReached(allowedNumSurveys: number) {
    this.patientService.getNumSubmittedSurveysForCurrentMonth().then(submittedNum => {
      const canNotify = !!this.translationService;

      if (!submittedNum || !canNotify) {
        return;
      }

      const warning = (msg) => !!this.toastService ? this.toastService.warning(msg, null, {timeOut: 15000}) : alert(msg);

      const tenPercent = allowedNumSurveys * 0.1;
      const left = allowedNumSurveys - submittedNum;
      const limitReached = submittedNum >= allowedNumSurveys;

      if (limitReached) {
        const msg = this._(KEYS.DEFAULT.WARNING_SURVEY_LIMIT_REACHED, {numSurveys: allowedNumSurveys});
        warning(msg);
        return
      }

      if (left <= tenPercent) {
        const msg = this._(KEYS.DEFAULT.WARNING_SURVEY_LIMIT_ALMOST_REACHED, {numSurveys: allowedNumSurveys, left});
        warning(msg);
        return;
      }
    })
  }
}
